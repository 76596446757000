import Signup from '../sign-up/pre-signup';
import OTP_VERIFICATION from '../sign-up/OTP-verification';

export const allRoutes = [
  {
    path: '/',
    element: <Signup />
  },
  {
    path: '/otp-verification/:id',
    element: <OTP_VERIFICATION />
  }
];